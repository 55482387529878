<template>
	<v-container fluid>
		<v-row align-content="center" justify="center">
			<v-spacer></v-spacer>
			<v-col cols="12" md="4">
				<v-card :elevation="6" outlined>
					<v-card-title>
						MHD Web Admin
					</v-card-title>
					<v-container fluid>
						<v-row dense>
							<v-col cols="12">
								<v-text-field v-model="username" :rules="[validation.required]" dense hide-details
											  label="Username" outlined></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field v-model="password" :rules="[validation.required]" dense hide-details
											  label="Password" outlined type="password"
											  @keydown.enter="executeLogin"></v-text-field>
							</v-col>
						</v-row>
					</v-container>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn @click="executeLogin" color="primary">Login</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
			<v-spacer></v-spacer>
		</v-row>
	</v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
	name: 'Login',
	data: () => ({
		username: '',
		password: '',
	}),
	methods: {
		...mapActions('auth', [
			'login'
		]),
		executeLogin() {
			this.login({username: this.username, password: this.password})
		}
	},
	computed: {
		...mapGetters('auth', [
			'loggedIn'
		]),
	},
	watch: {
		loggedIn(updated) {
			if (updated) {
				this.$router.push('/appliances/brand-groups')
			}
		}
	}
}
</script>

<style scoped>

</style>
